/* eslint-disable no-undef */
import React, {useState} from "react"
import { ToastBar, Toaster, toast } from "react-hot-toast"

import defaultLocale from "../../../defaultLocal"
import { renderLogosArray } from "./utils"
import useViewport from "../../../hooks/use-viewport"
import { CloseIcon } from "../../../assets/icons"

const FormWrapper = ({ Form }) => {
  const { isSmallScreen } = useViewport()
  const [isSubmittedView, setIsSubmittedView] = useState(false)

  return (
    <>
      <div>
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{ duration: 3500,
            success: {
              style: {
                backgroundColor: '#fff',
              },
            },
            error: {
              style: {
                backgroundColor: '#FEF9FA',
              }
            } }}
          containerStyle={{
            top: 55,
            left: 20,
            bottom: 20,
            right: 20,
          }}>
          {(t) => (
            <ToastBar toast={t}>
              {({ message }) => (
                <>
                  {message}
                  {t.type !== 'loading' && (
                    <button  onClick={() => toast.dismiss(t.id)}><CloseIcon /></button>
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
      </div>
      <div className="flex flex-col xl:flex-row items-center gap-7 xl:gap-20 justify-center align-middle h-full md:h-fit xl:h-full overflow-auto rounded-xl">
        {(!isSubmittedView || !isSmallScreen) &&
        <div className="flex flex-col">
          <div className="md:mt-32 text-blue xl:mt-0 font-Poppins-ExtraBold md:text-6xl text-left max-w-[548px] text-5xl px-[25px] md:px-0 md:pb-[7px]">
            {defaultLocale.couriersTitle}
          </div>
          {!isSmallScreen &&
             <div className="flex items-center">
               {renderLogosArray.map((renderElement) => renderElement())}
             </div>}
        </div>
        }

        <Form isSmallScreen={isSmallScreen} setIsSubmittedView={setIsSubmittedView} isSubmittedView={isSubmittedView} />
      </div>
    </>
  )
}

export default FormWrapper