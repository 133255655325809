import React from "react"

import { JTLogo, BostaPointerLogo, FedexLogo } from "../../../assets/icons"

const renderCardWithLogo = ({ key, logo: Logo }) => (
  <div key={key} className="bg-white flex w-[148px] h-[148px] max-h rounded-xl border border-solid border-light-grey-outline justify-center items-center shadow-card">
    <Logo width="124" height="56"/>
  </div>
)

export const renderLogosArray = [
  () => renderCardWithLogo({ key: 'fedex', logo: FedexLogo }),
  () => <BostaPointerLogo key='yfs' />,
  () => renderCardWithLogo({ key: 'jt', logo: JTLogo })
]
