import React from "react"


const ScrollingElement = ({ elements = [], elementClassName, smHorizontalGap='gap-3', mdHorizontalGap='md:gap-10', iconSize = {} }) => {
  //We need add ScrollingCouriers twice to make smooth transition at the end of animation, TODO: implement better behavior
  const ScrollingItems = () => (
    <div className={`flex items-center justify-center animate-infinite-scroll ${smHorizontalGap} ${mdHorizontalGap} pr-3 md:pr-10`}>
      {elements.map(({ label, logo: Logo }) =>
        (<div key={label}
          className={elementClassName}>
          <Logo width={iconSize.w} height={iconSize.h} />
        </div>))}
    </div>
  )

  return (
    <div className="w-full overflow-hidden inline-flex flex-nowrap">
      <ScrollingItems />
      <ScrollingItems />
    </div>
  )
}

export default ScrollingElement