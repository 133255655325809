import React from "react"

import GetStartedButton from "../../common/get-started-button"
import { COURIERS_LOGOS } from "./couriers.constants"
import ScrollingElement from "../../common/scrolling-element"
import useViewport from "../../../hooks/use-viewport"
import defaultLocale from "../../../defaultLocal"


const Couriers = () => {

  const { isSmallScreen } = useViewport()
  return (
    <div className="flex w-full flex-col gap-10 md:gap-[60px]">

      <div className="flex w-full flex-col gap-6 md:gap-8 items-center">
        <div className="flex flex-col gap-3 md:gap-5 justify-center max-w-[340px] md:max-w-[645px]">
          <label className="text-blue font-Poppins-ExtraBold text-5xl md:text-8xl text-center">{defaultLocale.couriersTitle}</label>
          <label className="text-black-text font-Poppins-Regular text-lg md:text-xl text-center">{defaultLocale.couriersSubtitle}</label>
        </div>
        <GetStartedButton  text={defaultLocale.getStartedForFreeBtn}/>
      </div>

      <ScrollingElement
        elements={COURIERS_LOGOS}
        iconSize={isSmallScreen? {w: '84', h: '40'} : { w: '160', h: '80' }}
        smHorizontalGap="gap-[6px]"
        mdHorizontalGap="md:gap-3"
        elementClassName="bg-white flex min-w-[100px] min-h-[100px] md:min-w-[192px] md:min-h-[192px] rounded-xl border border-solid border-light-grey-outline justify-center items-center shadow-card" />
    </div>
  )
}

export default Couriers